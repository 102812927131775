<template>
  <v-card flat class="pa-0">
    <v-toolbar :color="$vuetify.theme.dark ? '' : systemDetails.themecolor">
      <v-row class="px-2">
        <v-col cols="2" class="pa-0">
          <v-flex xs-2 class="mt-2">
            <v-btn class="button-small mx-1 float-left" outlined fab color="#FFFFFF" x-small @click="$router.push('/timer')">
              <v-icon>mdi-chevron-left</v-icon>
            </v-btn>
          </v-flex>
        </v-col>
        <v-col cols="8">
          <v-flex class="text-center" color="#FFFFFF">
            <span class="subtitle-1 font-weight-bold text-bold" color="#FFFFFF" style="color:white; text:bold"> {{ "Timer Report" }} </span>
          </v-flex>
        </v-col>
        <v-col cols="2" class="pa-0">
          <v-flex xs-2 class="mt-2">
            <v-btn color="success" x-small dark @click="showFilter = !showFilter" fab class="button-small mx-1 float-right">
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </v-flex>
        </v-col>
      </v-row>
    </v-toolbar>
    <v-row class="px-2 mt-1">
      <v-col cols="12" v-if="listOfRecords && listOfRecords.length > 0">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn  :loading="loading" v-on="on" v-bind="attrs" color="success" small dark @click="generateAsXLSX" fab class="button-small mx-1">
              <v-icon>mdi-file-excel</v-icon>
            </v-btn>
          </template>
          <span>{{ $t('excel')}}</span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn  :loading="pdfLoading" v-on="on" v-bind="attrs" color="red" small dark fab @click="generateAsPDF" class="button-small mx-1">
              <v-icon>mdi-file-pdf</v-icon>
            </v-btn>
          </template>
          <span>{{ $t('pdf')}}</span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn v-on="on" v-bind="attrs" color="blue" small dark fab @click="openEmailDialog" class="button-small mx-1">
              <v-icon>mdi-share-variant</v-icon>
            </v-btn>
          </template>
          <span>{{ $t('share')}}</span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn v-on="on" v-bind="attrs" color="primary" small dark fab @click="showHideColumnFilter" class="button-small mx-1">
              <v-icon>mdi-filter-outline</v-icon>
            </v-btn>
          </template>
          <span>{{ $t('filter')}}</span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn v-on="on" v-bind="attrs" color="info" small dark fab @click="toggleSortFilter" class="button-small mx-1">
              <v-icon>mdi-sort</v-icon>
            </v-btn>
          </template>
          <span>{{ $t('sort')}}</span>
        </v-tooltip>
      </v-col>
    </v-row>
    <v-navigation-drawer width="400" right overlay-opacity="0.75" fixed temporary v-model="showFilter">
      <v-toolbar :color="$vuetify.theme.dark ? '' : systemDetails.themecolor">
        <v-col cols="6">
          <v-flex class="text-left" color="#FFFFFF">
            <span class="subtitle-1 font-weight-bold text-bold float-left" color="#FFFFFF" style="color:white; text:bold"> {{ "Filters" }} </span>
          </v-flex>
        </v-col>
        <v-col cols="6">
          <v-btn icon small class="float-right" color="#FFFFFF" @click="showFilter = false" right>
            <v-icon> mdi-close-circle </v-icon>
          </v-btn>
        </v-col>
      </v-toolbar>
      <v-card outlined>
        <v-card-text class="pt-4">
          <v-form ref="filterForm" onsubmit="return false;">
            <v-autocomplete outlined hide-details v-model="filterObj.type" :label="$t('type')" @ :no-data-text="$t('noRecords')"
              :items="listReportFilterType" item-text="title" item-value="value" dense>
              <template v-slot:prepend>
                <!-- <v-btn fab class="elevation-0 mt-n1" @click="$router.push('timer')" :color="$vuetify.theme.dark ? '': systemDetails.themecolor" outlined x-small>
                  <v-icon>mdi-arrow-left</v-icon>
                </v-btn> -->
              </template>
            </v-autocomplete>

            <v-menu v-model="fromDateMenu" :close-on-content-click="false" max-width="290">
              <template #activator="{ on, attrs }">
                <v-text-field outlined dense class="my-4" clearable v-model="filterObj.from"
                :label="$t('startDate')">
                  <template #prepend>
                    <v-btn icon x-small v-on="on" v-bind="attrs" class="mt-1" hide-details @click="filterObj.fromDatePicker = $formatter.formatDate(filterObj.from, userDetails.dateformat, 'YYYY-MM-DD')">
                      <v-icon size="22"> mdi-calendar </v-icon>
                    </v-btn>
                  </template>
                </v-text-field>
              </template>
              <v-date-picker no-title v-model="filterObj.fromDatePicker" @input="fromDateMenu = false; filterObj.from = $formatter.formatDate(filterObj.fromDatePicker, 'YYYY-MM-DD', userDetails.dateformat)" color="primary" first-day-of-week='1'></v-date-picker>
            </v-menu>

            <v-menu v-model="tillDateMenu" :close-on-content-click="false" max-width="290">
              <template #activator="{ on, attrs }">
                <v-text-field outlined dense class="my-2" clearable v-model="filterObj.till"
                :label="$t('endDate')">
                  <template #prepend>
                    <v-btn icon x-small v-on="on" v-bind="attrs" class="mt-1" hide-details @click="filterObj.tillDatePicker = $formatter.formatDate(filterObj.till, userDetails.dateformat, 'YYYY-MM-DD')">
                      <v-icon size="22"> mdi-calendar </v-icon>
                    </v-btn>
                  </template>
                </v-text-field>
              </template>
              <v-date-picker no-title v-model="filterObj.tillDatePicker" :min="filterObj.fromDatePicker" @input="tillDateMenu = false;  filterObj.till = $formatter.formatDate(filterObj.tillDatePicker, 'YYYY-MM-DD', userDetails.dateformat)" first-day-of-week='1'></v-date-picker>
            </v-menu>

              <v-autocomplete  outlined dense class="my-2" v-if="filterObj.type === 3 && getTimerFormSettings && getTimerFormSettings.customer" :no-data-text="$t('noRecords')" v-model="filterObj.customers" :items="listOfCustomers"
              :label="$t('selectCustomers')" clearable hide-details item-text="name"  item-value="_id" multiple @keyup="getAccountSearch" @keydown="preventSpecialCharacters($event)" :search-input.sync="searchGetAccountValue">
                <template v-slot:selection="{ item, index }">
                <span v-if="index === 0" class="mt-1">{{ item.name }}</span>
                <span v-if="index === 1" class="grey--text caption">(+{{ filterObj.customers.length - 1 }} {{ $t('others') }})</span>
              </template>
              <!-- <template v-slot:append-item>
                <div v-if="showRenderLoadMore" v-intersect="getAccountList" class="pa-4 teal--text" @click="getAccountList()">
                    Loading more items ...
                </div>
              </template> -->
                <template v-slot:append-item>
                  <div v-if="showRenderLoadMore" v-intersect="debouncedTypeCustomers" class="pa-4 teal--text" @click="debouncedTypeCustomers()">
                    Loading more items ...
                  </div>
              </template>
            </v-autocomplete>

            <v-autocomplete outlined dense class="my-2" :no-data-text="$t('noRecords')" v-model="filterObj.employees" :items="getUsers"
              :label="$t('selectEmployees')" clearable hide-details item-text="name"  item-value="_id" multiple>
              <template v-slot:selection="{ item, index }">
                <span v-if="index === 0" class="mt-1">{{ item.name }}</span>
                <span v-if="index === 1" class="grey--text caption">(+{{ filterObj.employees.length - 1 }} {{ $t('others') }})</span>
              </template>
            </v-autocomplete>
            <!-- <v-autocomplete outlined dense class="my-2" :no-data-text="$t('noRecords')" v-model="filterObj.projects" :items="listOfProjects" v-if="getTimerFormSettings ? getTimerFormSettings.project : false"
              item-text="name" item-value="_id" hide-details clearable :label="$t('selectProject')" multiple>
              <template v-slot:selection="{ item, index }">
                <span v-if="index === 0" class="mt-1">{{ item.name }}</span>
                <span v-if="index === 1" class="grey--text caption">(+{{ filterObj.projects.length - 1 }} {{ $t('others') }})</span>
              </template>
            </v-autocomplete> -->

            <v-autocomplete  outlined dense class="my-2" :no-data-text="$t('noRecords')" v-model="filterObj.projects" :items="listOfProjects" v-if="getTimerFormSettings ? getTimerFormSettings.project : false"
              item-text="name" item-value="_id" hide-details clearable :label="$t('selectProject')" multiple @keyup="getProjectSearch" @keydown="preventSpecialCharacters($event)" :search-input.sync="searchGetProjectValue">
              <template v-slot:selection="{ item, index }">
                <span v-if="index === 0" class="mt-1">{{ item.name }}</span>
                <span v-if="index === 1" class="grey--text caption">(+{{ filterObj.projects.length - 1 }} {{ $t('others') }})</span>
              </template>
              <template v-slot:append-item>
                <div v-if="showRenderLoadMore" v-intersect="getProjectList" class="pa-4 teal--text" @click="getProjectList()">
                    Loading more items ...
                </div>
              </template>
            </v-autocomplete>

            <v-autocomplete outlined dense class="my-2" :no-data-text="$t('noRecords')" v-model="filterObj.worktypes" :items="getFileredWorkType"
              item-text="name" item-value="_id" clearable hide-details :label="$t('worktype')" multiple v-if="getTimerFormSettings ? getTimerFormSettings.worktype : false">
              <template v-slot:selection="{ item, index }">
                <span v-if="index === 0" class="mt-1">{{ item.name }}</span>
                <span v-if="index === 1" class="grey--text caption">(+{{ filterObj.worktypes.length - 1 }} {{ $t('others') }})</span>
              </template>
            </v-autocomplete>
            <v-autocomplete outlined dense class="my-2" :no-data-text="$t('noRecords')" v-model="filterObj.status" :items="hourStates"
              item-text="text" item-value="value" clearable hide-details :label="$t('status')" multiple>
              <template v-slot:selection="{ item, index }">
                <span v-if="index === 0" class="mt-1">{{ item.text }}</span>
                <span v-if="index === 1" class="grey--text caption">(+{{ filterObj.status.length - 1 }} {{ $t('others') }})</span>
              </template>
            </v-autocomplete>
            <!-- <v-checkbox value color="primary" hide-details v-model="filterObj.loadallprojects" :label="$t('loadAllProjects')" v-if="filterObj.type !== 3"></v-checkbox> -->
            <v-checkbox value color="primary" hide-details v-model="filterObj.showonlysum" :label="$t('onlySum')"></v-checkbox>
            <v-checkbox value color="primary" hide-details v-model="filterObj.showestimation" :label="$t('onlyEstimation')"></v-checkbox>
            <v-checkbox value color="primary" hide-details v-model="filterObj.groupactivities" :label="`${ $t('groupBy  ')} ${$t('worktype')}`" v-if="getTimerFormSettings ? getTimerFormSettings.worktype : false"></v-checkbox>
          </v-form>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-flex text-center>
            <v-btn color="success" :loading="filterSpin" @click="applyFilter" class="ma-1">{{ $t('runReport') }}
            </v-btn>
            <v-btn color="error" @click="clearForm" class="ma-1">{{ $t('clear') }}</v-btn>
          </v-flex>
        </v-card-actions>
      </v-card>
    </v-navigation-drawer>
    <v-progress-linear indeterminate v-if="filterSpin" color="primary darken-2"></v-progress-linear>
    <v-row v-if="listOfRecords && listOfRecords.length > 0" class="pa-0">
      <v-col cols="4" sm="3"></v-col>
      <v-col cols="8" sm="9">
        <v-row>
          <v-col cols="12" class="pt-0 pb-0" v-if="columnFilterToggle">
            <v-card class="px-2 mr-2" outlined tile>
              <transition name="slide-fade">
                <v-row class="ma-2">
                  <v-col cols="12" class="pa-0" md="2" sm="4" v-for="(field, index) in columnFilterFields" :key="`${index}_${field.name}`">
                    <v-checkbox v-if="field.name === 'all'" :indeterminate="someColumnsSelected" hide-details :label="field.text" v-model="filteredFields.all" @change="constructFilterFields('SELECT_ALL')"></v-checkbox>
                    <v-checkbox v-else hide-details :label="$t(field.text)" v-model="filteredFields.otherItems" :value="field.name" @change="constructFilterFields('OTHERS')" :disabled="field.name === 'hours'"></v-checkbox>
                  </v-col>
                </v-row>
              </transition>
            </v-card>
          </v-col>
          <v-col cols="12" class="pt-1 pb-0" v-if="sortFilterToggle">
            <v-card class="px-2 mr-2 mt-2" outlined>
              <v-row class="ma-1">
                <v-col cols="4">
                  <v-select filled hide-details :item-text="val => $t(val.text)" v-model="filterObj.sort_by" item-value="order" :items="reportHeaders" :label="$t('select')" dense></v-select>
                </v-col>
                <v-col cols="8">
                  <v-radio-group @change="applyFilter" row v-model="filterObj.sort_asc_or_desc">
                    <v-radio label="ASC" value="asc"></v-radio>
                    <v-radio label="DESC" value="desc"></v-radio>
                  </v-radio-group>
                </v-col>
                <!-- <v-col cols="4" sm="3" md="2" class="mt-2">
                  <v-btn small color="primary" @click="applyFilter" fab>{{ $t('go') }}</v-btn>
                </v-col> -->
              </v-row>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row class="px-1 pt-0">
      <v-col cols="12" sm="12" class="px-1" ref="getReportContent">
        <template v-if="listOfRecords && listOfRecords.length">
          <preview-report :list="listOfRecords" :filter="filterObj" style="width:100%"  :reportFilter="reportFilter" :headers="clonedHeaders" :timerFormSettings="getTimerFormSettings"></preview-report>
        </template>
        <v-alert icon="mdi-information-outline" v-else prominent text type="info" width="100%">
          {{ $t('noRecords') }}
        </v-alert>
      </v-col>
    </v-row>
    <v-dialog v-model="emailDialog" persistent width="400">
      <v-form ref="sendEmailForm" onsubmit="return false;">
        <v-card>
          <v-card-title :style="`background-color:${systemDetails.themecolor};color:${systemDetails.textcolor}`" class="headline pa-3" >{{ $t('sendReport') }}</v-card-title>
          <v-card-text>
            <v-text-field v-model="sendEmailData.emailIds" :label="$t('email')" :rules="$_requiredValidation"></v-text-field>
            {{ $t('emailnote') }}
            <v-autocomplete v-model="sendEmailData.type" :items="listSentEmailOptions" :no-data-text="$t('noRecords')" :label="$t('type')" item-text="title" item-value="value"></v-autocomplete>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions class="d-flex justify-center">
            <v-btn color="success" :loading="sentReportLoading" dark @click="sendReport"> {{ $t('ok') }} </v-btn>
            <v-btn color="error" dark @click="closeEmailDialog">{{ $t('cancel') }}</v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
  </v-card>
</template>
<script>
import { mapGetters } from 'vuex'
export default {
  data () {
    return {
      loading: false,
      pdfLoading: false,
      fromDateMenu: false,
      tillDateMenu: false,
      filterObj: {
        type: 1,
        fromDatePicker: '',
        tillDatePicker: '',
        projects: [],
        // from: this.$moment().startOf('month').format(this.userDetails.dateformat),
        // till: this.$moment().endOf('month').format(this.userDetails.dateformat)
        from: '',
        till: ''
      },
      filterSpin: false,
      columnFilterToggle: false,
      filteredFields: {
        all: true,
        otherItems: []
      },
      listOfRecords: [],
      clonedHeaders: [],
      sortOrder: '',
      sortFilterToggle: false,
      listOfActivities: [],
      sendEmailData: {
        emailIds: ''
      },
      emailDialog: false,
      isValidEmail: false,
      sentReportLoading: false,
      myProjects: [],
      reportFilter: {},
      listOfProjects: [],
      filteredProjects: [],
      listOfCustomers: [],
      reportHeaders: [],
      someColumnsSelected: false,
      limit: 30,
      skip: 0,
      showRenderLoadMore: true,
      searchGetProject: '',
      searchGetAccount: '',
      searchGetAccountValue: '',
      searchGetProjectValue: '',
      customerSkip: 0,
      showFilter: false,
      debouncedTypeCustomers: this.$formatter.debounce(this.getAccountList, 200)
    }
  },
  computed: {
    ...mapGetters(['getUsers', 'userDetails', 'getWorktypes', 'getTimerFormSettings', 'systemDetails']),
    getFileredWorkType () {
      if (this.filterObj.type === 4) return this.getWorktypes
      else return this.getWorktypes.filter(x => !x.isabsence)
    },
    listReportFilterType () {
      var result = [{
        value: 4,
        title: this.$t('perEmployee')
      }]
      if (this.getTimerFormSettings.customer) result.push({ value: 3, title: this.$t('perCustomer') })
      if (this.getTimerFormSettings.project) result.push({ value: 1, title: this.$t('perProject') })
      return result
    },
    columnFilterFields () {
      return [{
        name: 'all',
        text: 'All'
      }, ...this.reportHeaders]
    },
    hourStates () {
      return [{
        text: this.$t('notDelivered'),
        value: this.NOT_DELIVERED
      }, {
        text: this.$t('delivered'),
        value: this.DELIVERED
      }, {
        text: this.$t('approved'),
        value: this.APPROVED
      }, {
        text: this.$t('notApproved'),
        value: this.NOT_APPROVED
      }]
    }
  },
  created () {
    this.$store.dispatch('getTimerFormSettings').then(() => {
      const items = [
        {
          text: 'user',
          name: 'employee_name',
          width: '12%',
          order: 3,
          show: true
        }, {
          text: 'task',
          name: 'task_name',
          width: '10%',
          order: 2,
          show: this.getTimerFormSettings ? this.getTimerFormSettings.task : false
        }, {
          text: 'worktype',
          name: 'worktype_name',
          width: '10%',
          order: 1,
          show: this.getTimerFormSettings ? this.getTimerFormSettings.worktype : false
        }, {
          text: 'date',
          name: 'date',
          class: 'text-right text-no-wrap',
          width: '12%',
          order: 4,
          show: true
        }, {
          text: 'time',
          name: 'startEndTime',
          class: 'text-right text-no-wrap',
          width: '12%',
          order: 5,
          show: this.getTimerFormSettings ? this.getTimerFormSettings.start_end_time : false
        }, {
          text: 'amount',
          name: 'hours',
          class: 'text-right',
          width: '12%',
          order: 6,
          show: true
        }, {
          text: 'invoiceTo',
          name: 'toinvoice',
          width: '15%',
          order: 7,
          show: this.getTimerFormSettings ? this.getTimerFormSettings.to_invoice : false
        }, {
          text: 'O.50%',
          name: 'overtime50',
          width: '12%',
          order: 9,
          show: this.getTimerFormSettings ? this.getTimerFormSettings.overtime_50 : false
        }, {
          text: 'O.100%',
          name: 'overtime100',
          width: '12%',
          order: 10,
          show: this.getTimerFormSettings ? this.getTimerFormSettings.overtime_100 : false
        }, {
          text: 'pause',
          name: 'breakhr',
          width: '12%',
          order: 8,
          show: this.getTimerFormSettings ? this.getTimerFormSettings.breakhrs : false
        }, {
          text: 'timeCode',
          name: 'timecode_value',
          width: '12%',
          order: 11,
          show: this.getTimerFormSettings ? this.getTimerFormSettings.timecode : false
        }, {
          text: 'comments',
          name: 'comments',
          width: '15%',
          order: 13,
          show: this.getTimerFormSettings ? this.getTimerFormSettings.comments : false
        }, {
          text: 'internalComments',
          name: 'internalcomments',
          width: '15%',
          order: 12,
          show: this.getTimerFormSettings ? this.getTimerFormSettings.internal_comments : false
        }, {
          text: 'state',
          name: 'state',
          width: '13%',
          order: 14,
          show: true
        }]
      const activeHeaders = items.filter(x => x.show)
      const width = (100 / activeHeaders.length)
      activeHeaders.forEach(x => { if (x.name !== 'task_name' && x.name !== 'worktype_name') x.width = parseInt(width) })
      this.reportHeaders = activeHeaders
      this.clonedHeaders = this.$formatter.cloneVariable(this.reportHeaders)

      // this.$api.execute('get', 'moduledata/Project/timer').then(response => {
      //   response.data.forEach(project => {
      //     project.name = project.data.number ? `${project.data.number}-${project.data.name}` : project.data.name
      //   })
      //   this.listOfProjects = response.data
      // })
      // this.$api.execute('get', 'moduledata/Account/timer').then(response => {
      //   response.data.forEach(account => {
      //     account.name = account.data.name
      //   })
      //   this.listOfCustomers = response.data.filter(x => x.data && x.data.type && x.data.type === 'customer')
      // })
    })
    this.getProjectList()
    if (this.filterObj.type === 3) this.getAccountList()
    this.$store.dispatch('getWorktypes')
    this.filterObj.from = this.$moment().startOf('month').format(this.userDetails.dateformat)
    this.filterObj.till = this.$moment().endOf('month').format(this.userDetails.dateformat)
  },
  mounted () {
    const getFilterobj = localStorage.getItem(`${process.env.VUE_APP_NAME}_save_timer_report_filter`) ? JSON.parse(localStorage.getItem(`${process.env.VUE_APP_NAME}_save_timer_report_filter`)) : {}
    const usersList = this.getUsers.map(user => user._id)
    // Assuming getFilterobj.employees is an array of user IDs
    getFilterobj.employees = getFilterobj.employees.filter(id => usersList.includes(id))
    localStorage.setItem(`${process.env.VUE_APP_NAME}_save_timer_report_filter`, JSON.stringify(getFilterobj))
    this.filterObj = getFilterobj
    if (!getFilterobj || Object.keys(getFilterobj).length === 0) {
      const perEmployeeObj = { from: this.$moment().startOf('month').format(this.userDetails.dateformat), till: this.$moment().endOf('month').format(this.userDetails.dateformat), activities: null, type: 4 }
      this.filterObj = perEmployeeObj
      this.$set(this.filterObj, 'type', 4)
      localStorage.setItem(`${process.env.VUE_APP_NAME}_save_timer_report_filter`, JSON.stringify(this.filterObj))
    }
    // this.filterObj.type = this.filterObj.type || this.listReportFilterType[0].value
    if (!this.filterObj.from && !this.filterObj.to) {
      this.filterObj.from = this.$moment().startOf('month').format(this.userDetails.dateformat)
      this.filterObj.till = this.$moment().endOf('month').format(this.userDetails.dateformat)
    }
    this.applyFilter()
  },
  components: {
    'preview-report': () => import('@/components/Report/PreviewReport.vue')
  },
  watch: {
    'filterObj.customers' (val) {
      if (this.filterObj.type === 3) {
        this.getListRelatedProjects(val)
      }
    },
    'filterObj.projects' (val) {
      if (this.filterObj.type === 2) {
        if (val && val.length > 0) {
          this.getActivityByProject(val)
        } else this.listOfActivities = []
      }
      this.filterObj.activities = null
    },
    'filterObj.type' () {
      this.listOfRecords = []
      // this.clearForm()
    }
  },
  methods: {
    closeEmailDialog () {
      this.sendEmailData.emailIds = null
      this.$refs.sendEmailForm.resetValidation()
      this.emailDialog = false
    },
    getProjectList () {
      this.$api.execute('get', `moduledata/Project/live_search?skip=${this.skip}&limit=${this.limit}&searchTerm=${this.searchGetProject}`)
        .then(response => {
          response.data.data.forEach(project => {
            project.name = project.data.number ? `${project.data.number}-${project.data.name}` : project.data.name
          })
          if (response.data.data.length > 0) {
            this.listOfProjects = this.listOfProjects.concat(response.data.data)
            this.skip += 30
          } else {
            this.showRenderLoadMore = false
          }
        })
    },

    getProjectSearch (event) {
      const key = event.key
      if ((/^[a-zA-Z0-9]$/.test(key)) || key === 'Backspace') {
        this.searchGetProject = this.searchGetProjectValue
      }
      this.skip = 0
      if (key !== 'Control' && key !== 'Alt' && key !== 'Shift') {
        this.getProjectList()
      }
    },
    getAccountList () {
      this.$api.execute('get', `moduledata/Account/live_search?skip=${this.customerSkip}&limit=${this.limit}&searchTerm=${this.searchGetAccount}`).then(response => {
        response.data.data.forEach(account => {
          account.name = account.data.name
        })
        if (response.data.data.length > 0) {
          this.listOfCustomers = this.listOfCustomers.concat(response.data.data.filter(x => x.data && x.data.type && x.data.type === 'customer'))
          // this.listOfProjects = this.listOfProjects.concat(response.data.data)
          // this.skip += 30
          this.customerSkip += 30
        } else {
          this.showRenderLoadMore = false
        }
      })
    },

    getAccountSearch (event) {
      const key = event.key
      if ((/^[a-zA-Z0-9]$/.test(key)) || key === 'Backspace') {
        this.searchGetAccount = this.searchGetAccountValue
      }
      this.skip = 0
      this.customerSkip = 0
      if (key !== 'Control' && key !== 'Alt' && key !== 'Shift') {
        this.getAccountList()
      }
    },

    setNullValuesFalse (model) {
      const booleankeys = ['groupactivities', 'showonlysum', 'showestimation']
      for (const i in model) {
        if (booleankeys.includes(i) && !model[i]) model[i] = false
      }
      return model
    },
    openEmailDialog () {
      this.isValidEmail = false
      this.sendEmailData.type = 1
      this.emailDialog = true
    },
    constructFilterFields (from) {
      if (from === 'SELECT_ALL') {
        if (this.filteredFields.all) {
          this.filteredFields.otherItems = [...this.reportHeaders.map(x => x.name)]
          this.someColumnsSelected = false
        } else {
          this.filteredFields.otherItems = ['hours']
          this.someColumnsSelected = true
        }
      } else {
        const value = this.filteredFields.otherItems
        if (value && value.length < this.reportHeaders.length) {
          this.filteredFields.all = false
          this.someColumnsSelected = true
        } else {
          this.filteredFields.all = true
          this.someColumnsSelected = false
        }
      }
      const filters = this.filteredFields.otherItems
      this.filterByHeadersToReport(filters)
    },
    applyFilter () {
      this.filterSpin = true
      // const transformData = { date: { list: ['from', 'till'], from: 'DD.MM.YYYY', to: 'MM.DD.YYYY' } }
      // let model = this.$formatter.formatModel(this.filterObj, transformData)
      let model = this.$formatter.cloneVariable(this.filterObj)
      model.till = this.$formatter.formatDate(model.till, this.userDetails.dateformat, 'YYYY-MM-DDT00:00:00')
      let url = ''
      switch (this.filterObj.type) {
        case 1:
          url = 'preview_per_project'
          break
        case 4:
          url = 'preview_per_employee'
          break
        case 3:
          url = 'preview_per_customer'
          break
        default:
          break
      }
      model = this.setNullValuesFalse(model)
      model.from = this.$formatter.formatDate(model.from, this.userDetails.dateformat, 'YYYY-MM-DDT00:00:00')
      this.$api.execute('post', `timerreports/${url}`, model)
        .then(({ data }) => {
          if (data) {
            switch (this.filterObj.type) {
              case 1:
                data = data.map((report) => ({
                  ...report,
                  hours: report.hours.map((hour) => ({
                    ...hour
                    // date: this.$formatter.fromUtcToLocal(hour.date, 'DD.MM.YYYYTHH.mm.ss', 'DD.MM.YYYYTHH.mm.ss'),
                    // start_date: this.$formatter.fromUtcToLocal(hour.start_date, 'DD.MM.YYYYTHH.mm.ss', 'DD.MM.YYYYTHH.mm.ss'),
                    // end_date: this.$formatter.fromUtcToLocal(hour.end_date, 'DD.MM.YYYYTHH.mm.ss', 'DD.MM.YYYYTHH.mm.ss')
                  }))
                }))
                break
              case 3:
              case 4:
                data = data.map((report) => ({
                  ...report,
                  projects: report.projects.map((project) => ({
                    ...project,
                    hours: project.hours.map((hour) => ({
                      ...hour
                      // date: this.$formatter.fromUtcToLocal(hour.date, 'DD.MM.YYYYTHH.mm.ss', 'DD.MM.YYYYTHH.mm.ss'),
                      // start_date: this.$formatter.fromUtcToLocal(hour.start_date, 'DD.MM.YYYYTHH.mm.ss', 'DD.MM.YYYYTHH.mm.ss'),
                      // end_date: this.$formatter.fromUtcToLocal(hour.end_date, 'DD.MM.YYYYTHH.mm.ss', 'DD.MM.YYYYTHH.mm.ss')
                    }))
                  }))
                }))
                break
            }
            this.listOfRecords = data
          }
          this.reportFilter = this.$formatter.cloneVariable(this.filterObj)
          localStorage.setItem(`${process.env.VUE_APP_NAME}_save_timer_report_filter`, JSON.stringify(this.reportFilter))
        })
        .finally(() => {
          this.filterSpin = false
        })
    },
    clearForm (resetDateAlso) {
      const { till, from } = this.$formatter.cloneVariable(this.filterObj)
      this.$refs.filterForm.reset()
      this.listOfRecords = []
      this.filterObj.sort_by = ''
      this.filterObj.sort_asc_or_desc = ''
      localStorage.removeItem(`${process.env.VUE_APP_NAME}_save_timer_report_filter`)
      this.$nextTick(() => {
        this.filterObj.from = resetDateAlso ? this.$moment().startOf('month').format(this.userDetails.dateformat) : from
        this.filterObj.till = resetDateAlso ? this.$moment().endOf('month').format(this.userDetails.dateformat) : till
      })
    },
    showHideColumnFilter () {
      this.columnFilterToggle = !this.columnFilterToggle
      if (this.columnFilterToggle) {
        const getHeaders = this.$formatter.cloneVariable(this.clonedHeaders)
        this.filteredFields = { all: true, otherItems: [...getHeaders.map(x => x.name)] } // all: getHeaders.length === 15
      }
    },
    filterByHeadersToReport (fields) {
      if (fields && fields.length) {
        const getHeaders = this.$formatter.cloneVariable(this.reportHeaders)
        const filteredFields = []
        for (const field of getHeaders) {
          // const filtered = getHeaders.find(x => x.name === field)
          if (fields.includes(field.name)) filteredFields.push(field)
        }
        this.clonedHeaders = filteredFields.sort(this.compareTab)
      }
    },
    generateAsPDF () {
      this.pdfLoading = true
      var getBase64Content = { content: this.getPDFContent() }
      this.$api.execute('post', 'timerreports/generate_pdf/', getBase64Content)
        .then((response) => {
          const fileName = response.data
          window.open(process.env.VUE_APP_URL + 'files/download/?fileName=' + fileName, '_blank')
        }).finally(() => {
          this.pdfLoading = false
        })
    },
    sendReport () {
      if (this.$refs.sendEmailForm.validate()) {
        if (this.sendEmailData.emailIds && this.sendEmailData.emailIds.length > 0) {
          this.isValidEmail = true
          const getMailIDsString = this.sendEmailData.emailIds
          const listOfMailIds = getMailIDsString.split(',')
          if (listOfMailIds && listOfMailIds.length > 0) {
            for (let i = 0; i < listOfMailIds.length; i++) {
              if (listOfMailIds[i]) {
                listOfMailIds[i] = listOfMailIds[i].trim()
                if (this.$formatter.validEmail(listOfMailIds[i])) this.isValidEmail = false
                else this.isValidEmail = true
              }
            }
          }
          const getHeaders = this.$formatter.cloneVariable(this.clonedHeaders)
          this.filterObj.columns = [...getHeaders.map(x => x.order)]
          // const transformData = { date: { list: ['from', 'till'], from: 'DD.MM.YYYY', to: 'MM.DD.YYYY' } }
          // let model = this.$formatter.formatModel(this.filterObj, transformData)
          // let model = this.filterObj
          let model = this.$formatter.cloneVariable(this.filterObj)
          model.from = this.$formatter.formatDate(model.from, this.userDetails.dateformat, 'YYYY-MM-DDT00:00:00')
          model.till = this.$formatter.formatDate(model.till, this.userDetails.dateformat, 'YYYY-MM-DDT00:00:00')
          if (this.filterObj.groupactivities) this.filterObj.columns = [1, 6, 7].filter(x => this.filterObj.columns.includes(x))
          if (!this.isValidEmail) {
            this.sentReportLoading = true
            if (this.sendEmailData.type === 1) {
              model = { to: listOfMailIds, filesendtype: this.sendEmailData.type }
              model.content = this.getPDFContent()
            } else if (this.sendEmailData.type === 2) {
              model.to = listOfMailIds
              model.filesendtype = this.sendEmailData.type
            } else {
              model.to = listOfMailIds
              model.filesendtype = this.sendEmailData.type
              model.content = this.getPDFContent()
            }
            model = this.setNullValuesFalse(model)
            this.$api.execute('post', 'timerreports/share', model)
              .then((response) => {
                this.$root.$emit('snackbar', { snackbar: true, color: 'success', text: 'sentSuccess' })
                this.sendEmailData.emailIds = ''
                this.$refs.sendEmailForm.resetValidation()
                this.emailDialog = false
              }).finally(() => {
                this.sentReportLoading = false
              })
          } else {
            this.$root.$emit('snackbar', { snackbar: true, color: 'error', text: 'invalidEmail' })
          }
        } else this.$root.$emit('snackbar', { snackbar: true, color: 'error', text: 'invalidEmail' })
      }
    },
    getPDFContent () {
      const styleLink = `<link href="https://maxcdn.bootstrapcdn.com/bootstrap/3.3.7/css/bootstrap.min.css" rel="stylesheet" />
        <style>
        td { border-right: 1px solid rgba(0, 0, 0, 0.12); border-bottom: 1px solid rgba(0, 0, 0, 0.12); padding-left: 5px; padding-right: 5px; }
        .report_header{background: #354759 !important;color: white;}
        .table{font-family: initial; font-size: 12px;}
        table {width: 100%;}
        .padding-remove { padding: 0 }
        .no--border { border: none !important }
        tbody tr    { page-break-inside:avoid;}
        tbody tr td { text-align: justify;word-break: break-word;}
        .filter-per-activity tbody tr:nth-child(1), .filter-per-customer tbody tr:nth-child(1){
          background: #5bc0de;
          color: white;
        }
        </style>`
      const getHTMLContent = `<!DOCTYPE html><html><head><meta name="viewport" content="width=device-width" />
        <meta charset="utf-8"/>${styleLink}</head><body><div class="container-fluid">${this.$refs.getReportContent.innerHTML}</div></body></html>`
      return btoa(unescape(encodeURIComponent(getHTMLContent)))
    },
    // Generate as XLSX file
    generateAsXLSX () {
      this.$store.commit('showLoader')
      this.loading = true
      const getHeaders = this.$formatter.cloneVariable(this.clonedHeaders)
      this.filterObj.columns = [...getHeaders.map(x => x.order)]
      if (this.filterObj.groupactivities) this.filterObj.columns = [1, 6, 7].filter(x => this.filterObj.columns.includes(x))
      // const transformData = { date: { list: ['from', 'till'], from: 'DD.MM.YYYY', to: 'MM.DD.YYYY' } }
      // const model = this.$formatter.formatModel(this.filterObj, transformData)
      // const model = this.filterObj
      const model = this.$formatter.cloneVariable(this.filterObj)
      model.from = this.$formatter.formatDate(model.from, this.userDetails.dateformat, 'YYYY-MM-DDT00:00:00')
      model.till = this.$formatter.formatDate(model.till, this.userDetails.dateformat, 'YYYY-MM-DDT00:00:00')
      const booleankeys = ['groupactivities', 'showonlysum', 'showestimation']
      for (const i in model) {
        if (booleankeys.includes(i) && !model[i]) model[i] = false
      }
      this.$api.execute('post', 'timerreports/generate_xlsx', model)
        .then((response) => {
          const fileName = response.data
          this.$store.commit('hideLoader')
          window.open(process.env.VUE_APP_URL + 'files/download/?fileName=' + fileName, '_blank')
        }).finally(() => {
          this.loading = false
        })
    },
    toggleSortFilter () {
      this.sortFilterToggle = !this.sortFilterToggle
    },
    compareTab (a, b) {
      if (a.tab < b.tab) {
        return -1
      } else if (a.tab > b.tab) {
        return 1
      } else {
        return 0
      }
    },
    getListRelatedProjects (val) {
      // Query needs to be improved
      const queries = [{
        $project: {
          _id: { $toString: '$_id' },
          DataName: { name: 1 },
          DataNumber: { number: 1 },
          Relations: 1,
          relationObjects: {
            $filter: {
              input: '$Relations',
              as: 'item',
              cond: { $in: ['$$item._id', val] }
            }
          }
        }
      }]
      this.$api.execute('post', 'moduledata/Project/query', queries).then(response => {
        const array = []
        const customerids = this.$formatter.cloneVariable(val)
        response.data.forEach(x => {
          if (x.relationobjects && x.relationobjects.length) {
            const relationobjects = x.relationobjects.map(x => x._id)
            if (customerids.some(item => relationobjects.includes(item))) array.push(x)
          }
        })
        array.map(x => {
          x.name = x.data.name
        })
        this.listOfProjects = this.$formatter.cloneVariable(array)
      })
    }
  }
}
</script>
